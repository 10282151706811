import type { Router, AppLocation, HistoryLocation, ResolvedRoute, RouteScrollOptions } from './types';
import type { Epic } from 'behavior/types';
import type { OfflineModeChangedAction } from 'behavior/app';
import type { EventAction } from 'behavior/events';
import { combineEpics, ignoreIfVisualDesigner } from 'utils/rxjs';
import { of, merge, identity, EMPTY } from 'rxjs';
import {
  map, pluck, tap, switchMap,
  takeUntil, filter,
  ignoreElements,
  mergeMap,
  first,
  exhaustMap,
  mapTo,
  withLatestFrom,
  switchMapTo,
  delay,
  catchError,
} from 'rxjs/operators';
import {
  RoutingAction,
  NAVIGATION_REQUESTED, navigationRequested,
  STATUS_CODE_RESOLVED,
  GO_BACK,
  NAVIGATE_TO_PREVIOUS,
  RELOAD_LOCATION,
  REWRITE_TO,
  NAVIGATING,
  NAVIGATED,
  SAVE_SCROLL_POSITION,
  redirectTo, startNavigation, initialize, reloadLocation,
} from './actions';
import { NAVIGATION_REQUESTED as NAVIGATION_REQUESTED_EVENT } from 'behavior/events';
import { locationChanged } from 'behavior/events';
import { APP_INIT, OFFLINE_MODE_CHANGED, OfflineModeSupport } from 'behavior/app';
import { areLocationsEqual, createLocation, createUrl } from './helpers';
import { resolveQuery } from './queries';
import {
  trailingMiddleware,
  languageMiddleware,
  canonicalMiddleware,
} from './middleware';
import { requestRoute } from 'behavior/route';
import { RouteData, RouteName, routesBuilder } from 'routes';
import { ShopAccountType } from 'behavior/user/constants';
import { logout } from 'behavior/user';
import { visibility$ } from 'utils/rxjs/eventsObservables';
import { ofType } from 'redux-observable';
import { Action } from 'redux';

export function createRoutingEpic(router: Router, navigationMiddleware = [
  languageMiddleware,
  trailingMiddleware,
  canonicalMiddleware,
]) {
  const historyChangeEpic: Epic<RoutingAction> = action$ => {
    const onInit$ = action$.pipe(
      ofType(APP_INIT),
      first(),
      mergeMap(_ => {
        const location = convertToAppLocation(router.location);
        return of(initialize(location), navigationRequested(location));
      }),
    );
    const navigating$ = action$.pipe(
      ofType(NAVIGATION_REQUESTED),
      map(({ payload: { location } }) => location),
    );
    const navigated$ = action$.pipe(
      ofType(NAVIGATED),
      map(({ payload: { location } }) => location),
    );

    const onHistoryChange$ = router.locationObservable.pipe(
      withLatestFrom(merge(of(router.location), navigating$, navigated$)),
      filter(([newLocation, currentLocation]) => !areLocationsEqual(newLocation, currentLocation)),
      map(([historyLocation, _]) => navigationRequested(convertToAppLocation(historyLocation))),
    );
    return merge(onInit$, onHistoryChange$);
  };

  const navigationEpic: Epic<RoutingAction> = (action$, state$, dependencies) => action$.pipe(
    ofType(NAVIGATION_REQUESTED),
    pluck('payload'),
    filter(({ location, statusCode }) => router.onNavigating(location, statusCode)),
    switchMap(({ location, routeData, replaceHistory }) => {
      if (routeData && routeData.routeName) {
        if (!routeData.params?.language)
          routeData = { ...routeData, params: { ...routeData.params, language: state$.value.localization.currentLanguage.id } };

        const scrollPosition = router.location.state?.scrollPosition;
        let routeScrollOptions = routeData.options as RouteScrollOptions;
        const restoreScroll = routeScrollOptions
          ? routeScrollOptions.initialScrollPosition ? false : routeScrollOptions.restoreScroll
          : false;
        const prevLocation = state$.value.routing.previous?.location;
        const additionalOptions = getAdditionalRouteDataOptions(scrollPosition, replaceHistory, restoreScroll, location, prevLocation);
        if (additionalOptions) {
          routeData = { ...routeData, options: { ...routeData.options, ...additionalOptions } };
          routeScrollOptions = routeData.options as RouteScrollOptions;
        }

        if (routeScrollOptions?.initialScrollPosition)
          location = { ...location, state: { ...location.state, initialScrollPosition: routeScrollOptions.initialScrollPosition } };

        return of(startNavigation(location, routeData));
      }
      else {
        return dependencies.api.graphApi<{ routing: { route: ResolvedRoute } }>(resolveQuery, {
          path: location.pathname + location.search,
        }).pipe(
          map(({ routing }) => {
            for (const middleware of navigationMiddleware) {
              const action = middleware(routing.route, location, dependencies);

              if (action)
                return action;
            }

            return startNavigation(location, routing.route);
          }),
          catchError(e => {
            dependencies.logger.error(e);
            return of(startNavigation(location, { routeName: RouteName.Error }));
          }),
          takeUntil(action$.pipe(ofType(NAVIGATING))),
        );
      }
    }),
  );

  const navigationEventEpic: Epic<EventAction> = (action$, state$) => {
    const requestNavigation = (routeData: RouteData | undefined, url: string, omitScroll: boolean | undefined, replaceHistory: boolean | undefined) => {
      const location = createLocation<AppLocation>(url);
      if (omitScroll)
        location.state = { omitScroll };

      return navigationRequested(location, undefined, routeData, replaceHistory);
    };

    return action$.pipe(
      ofType(NAVIGATION_REQUESTED_EVENT),
      pluck('payload'),
      switchMap(({ omitScroll, replaceHistory, ...route }) => {
        if ('url' in route) {
          let url = route.url;
          const to = 'to' in route ? route.to : undefined;

          if (url[0] === '#') {
            const location = state$.value.routing.location!;
            url = location.pathname + location.search + url;
          }

          return of(requestNavigation(to, url, omitScroll, replaceHistory));
        } else {
          return requestRoute(route.to, state$).pipe(
            map(path => requestNavigation(route.to, path, omitScroll, replaceHistory)),
          );
        }
      }),
    );
  };

  const navigationCompletedEpic: Epic<RoutingAction> = (action$, state$) => action$.pipe(
    ofType(NAVIGATING),
    map(({ payload: { location } }) => location),
    filter(newLocation => !areLocationsEqual(newLocation, state$.value.routing.location)),
    mapTo(locationChanged()),
  );

  const syncWithHistoryEpic: Epic<RoutingAction> = action$ => action$.pipe(
    ofType(NAVIGATION_REQUESTED),
    pluck('payload'),
    filter(({ location }) => !areLocationsEqual(location, router.location)),
    tap(({ location, replaceHistory }) => {
      let scrollPosition;
      if (router.location.state?.scrollPosition) {
        const { current, previous } = router.location.state.scrollPosition;
        if (location.state?.omitScroll && current != null)
          scrollPosition = { current };

        const newPrevious = replaceHistory ? previous : current;
        if (newPrevious != null)
          scrollPosition = { ...scrollPosition, previous: newPrevious };
      }

      const historyLocation = convertToHistoryLocation(location);
      if (scrollPosition)
        historyLocation.state = { scrollPosition };

      replaceHistory ? router.replace(historyLocation) : router.push(historyLocation);
    }),
    ignoreElements(),
  );

  const statusCodeEpic: Epic<RoutingAction> = action$ => action$.pipe(
    ofType(STATUS_CODE_RESOLVED),
    tap(({ payload: { statusCode } }) => router.onStatusCodeResolved(statusCode)),
    ignoreElements(),
  );

  const goBackEpic: Epic<RoutingAction> = action$ => action$.pipe(
    ofType(GO_BACK),
    tap(() => router.goBack()),
    ignoreElements(),
  );

  const navigateToPreviousEpic: Epic<RoutingAction> = (action$, state$) => action$.pipe(
    ofType(NAVIGATE_TO_PREVIOUS),
    mergeMap(({ payload }) => {
      const backTo = state$.value.page.backTo;
      if (backTo && backTo.url)
        return of({ routeData: backTo.routeData, location: createLocation(backTo.url) });

      const prev = state$.value.routing.previous;
      if (prev && !payload.ignoredRouteNames.includes(prev.routeData.routeName))
        return of({ ...prev, routeData: { ...prev.routeData, options: { restoreScroll: true } } });

      const routeData = payload.fallbackRoute || routesBuilder.forHome();
      const toLocation = (path: string) => ({ routeData, location: createLocation(path) });
      return map(toLocation)(requestRoute(routeData, state$));
    }),
    filter<{ location: AppLocation; routeData: RouteData }>(Boolean),
    map(data => navigationRequested(data.location, 200, data.routeData)),
  );

  const reloadLocationEpic: Epic<RoutingAction> = (action$, state$) => action$.pipe(
    ofType(RELOAD_LOCATION),
    map(_ => {
      const currScrollPosition = router.location.state?.scrollPosition?.current;
      const { value, screenWidth } = state$.value.routing.location!.state?.initialScrollPosition || {};
      const currentLocation = state$.value.routing.location!;

      const location = currScrollPosition && (currScrollPosition.screenWidth !== screenWidth || currScrollPosition.value !== value)
        ? { ...currentLocation!, state: { ...currentLocation.state, initialScrollPosition: currScrollPosition } }
        : currentLocation;

      return startNavigation(location, state$.value.routing.routeData!);
    }),
  );

  const rewriteToEpic: Epic<RoutingAction> = (action$, state$) => action$.pipe(
    ofType(REWRITE_TO),
    pluck('payload'),
    map(({ routeData, omitScroll }) => {
      const location = state$.value.routing.location!;
      if (omitScroll)
        return startNavigation({ ...location, state: { ...location.state, omitScroll } }, routeData);

      return startNavigation(location, routeData);
    }),
  );

  const authRedirectsEpic: Epic<RoutingAction> = (action$, state$, dependencies) => {
    const redirect = (user: typeof state$.value.user) => {
      const routing = state$.value.routing;
      const { location: currentLocation, routeData: currentRoute } = routing.navigatingTo || routing;

      let route;
      if (!user.isAuthenticated) {
        if (currentRoute!.routeName === RouteName.Login)
          return EMPTY;

        route = routesBuilder.forLogin();
      } else if (user.shopAccountType === ShopAccountType.SalesAgent && !user.isImpersonating)
        route = routesBuilder.forRepresent();
      else
        return of(logout()); // User is still authenticated on client, but on server the session has been invalidated.

      const isServer = dependencies.scope === 'SERVER';
      // On client side 401 status will replace current history record, 302 - adds new record.
      // So use 401 for redirects to login/represent during client navigation to avoid loop on going back via browser button
      // and to be consistent with SSR redirect in number of history records.
      const statusCode = isServer || !routing.navigatingTo ? 302 : 401;

      const backUrl = createUrl(currentLocation!);
      const redirectRoute = {
        ...route,
        options: { backTo: { url: backUrl, routeData: currentRoute } },
      };

      return requestRoute(route, state$).pipe(map(path => {
        const redirectUrl = isServer
          ? `${path}?backurl=${backUrl}`
          : path;
        return redirectTo(redirectUrl, statusCode, redirectRoute);
      }));
    };

    return merge(
      action$.pipe(
        ofType(STATUS_CODE_RESOLVED),
        filter(action => action.payload.statusCode === 401),
      ),
      dependencies.api.errors$.pipe(
        filter(e => e.status && e.status === 401),
      ),
    ).pipe(
      exhaustMap(_ => {
        const user = state$.value.user;
        if (user.initialized)
          return redirect(user);

        return state$.pipe(
          first(({ user }) => user.initialized),
          exhaustMap(({ user }) => redirect(user)),
        );
      }),
    );
  };

  const authSynchronizationReloadEpic: Epic<Action> = (action$, state$, { api }) => {
    if (!api.authChanges$)
      return EMPTY;

    return api.authChanges$.pipe(
      ignoreIfVisualDesigner(state$),
      switchMapTo(visibility$.pipe(
        first(identity),
        delay(50),
        mapTo(reloadLocation()),
        takeUntil(action$.pipe(ofType(NAVIGATING, NAVIGATED))),
      )),
    );
  };

  const serviceUnavailableEpic: Epic<OfflineModeChangedAction> = action$ => action$.pipe(
    ofType(OFFLINE_MODE_CHANGED),
    first(),
    filter(({ payload: { offlineMode, offlineModeSupport } }) =>
      offlineMode && offlineModeSupport === OfflineModeSupport.Disabled),
    tap(_ => router.onStatusCodeResolved(503)),
    ignoreElements(),
  );

  const saveScrollPositionInHistoryEpic: Epic<RoutingAction> = action$ => action$.pipe(
    ofType(SAVE_SCROLL_POSITION),
    pluck('payload'),
    tap(({ value, screenWidth }) => {
      const location = {
        ...router.location,
        state: {
          ...router.location.state,
          scrollPosition: {
            ...router.location.state?.scrollPosition,
            current: { value, screenWidth },
          },
        },
      };

      router.replace(location);
    }),
    ignoreElements(),
  );

  return combineEpics(
    serviceUnavailableEpic,
    historyChangeEpic,
    authRedirectsEpic,
    navigationEventEpic,
    navigationEpic,
    syncWithHistoryEpic,
    statusCodeEpic,
    goBackEpic,
    navigateToPreviousEpic,
    reloadLocationEpic,
    rewriteToEpic,
    navigationCompletedEpic,
    authSynchronizationReloadEpic,
    saveScrollPositionInHistoryEpic,
  ) as Epic<RoutingAction | EventAction | Action | OfflineModeChangedAction>;
}

function getAdditionalRouteDataOptions(
  scrollPosition: Required<HistoryLocation>['state']['scrollPosition'],
  replaceHistory: boolean | undefined,
  restoreScroll: boolean | undefined,
  location: Readonly<AppLocation>,
  prevLocation: Readonly<AppLocation> | undefined,
) {
  const navigatingFromScrollPosition = scrollPosition?.current;
  const prevToNavigatingFromScrollPosition = scrollPosition?.previous;
  const options: Record<string, unknown> = {};

  const backToScrollPosition = replaceHistory ? prevToNavigatingFromScrollPosition : navigatingFromScrollPosition;
  if (backToScrollPosition)
    options.backToScrollPosition = backToScrollPosition;

  if (replaceHistory)
    options.replaceHistory = replaceHistory;

  if (restoreScroll && prevToNavigatingFromScrollPosition && areLocationsEqual(location, prevLocation))
    options.initialScrollPosition = prevToNavigatingFromScrollPosition;

  if (!Object.keys(options).length)
    return;

  return options;
}

function convertToAppLocation(historyLocation: Readonly<HistoryLocation>): AppLocation {
  const location = { ...historyLocation };
  delete location.key;
  delete location.state;

  return location as AppLocation;
}

function convertToHistoryLocation(location: Readonly<AppLocation>): HistoryLocation {
  const historyLocation = { ...location };
  delete historyLocation.state;

  return historyLocation as HistoryLocation;
}
