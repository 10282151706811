import type { ProductCategoryCollection, Product, Section } from './types';
import type { SearchSuggestionsAction } from './actions';
import type { Epic } from 'behavior/types';
import { SEARCH_PRODUCT_SUGGESTIONS, productsSearchSuggestionsCompleted } from './actions';
import { ofType } from 'redux-observable';
import { switchMap, map, pluck } from 'rxjs/operators';
import { searchSuggestionsQuery } from './queries';
import { routesBuilder } from 'routes';
import { retryWithToast } from 'behavior/errorHandling';

type ResponseProduct = {
  id: string;
  title: string | null;
  url: string | null;
  isParent: boolean;
  categoriesPaths: ProductCategoryCollection[];
  image: {
    small: string | null;
  } | null;
};

type SearchSuggestionsResponse = {
  catalog: {
    products: {
      products: ResponseProduct[];
    };
  };
};

const epic: Epic<SearchSuggestionsAction> = (action$, state$, { api, logger }) => {
  return action$.pipe(
    ofType(SEARCH_PRODUCT_SUGGESTIONS),
    pluck('payload'),
    switchMap(options =>
      api.graphApi<SearchSuggestionsResponse>(searchSuggestionsQuery, { options, loadCategories: true }).pipe(
        map(mapSuggestions),
        retryWithToast(action$, logger),
      ),
    ),
  );
};

export default epic;

export function mapSuggestions(data: SearchSuggestionsResponse) {
  const suggestedProducts: Product[] = [];

  //3.5. Product grouping – Quick order and search related changes
  const sections: Section[] = [];
  const suggestedRegularProducts: Product[] = [];
  const suggestedParentProducts: Product[] = [];

  for (const product of data.catalog.products.products) {
    const suggestedProduct: Product = {
      ...product,
      routeData: routesBuilder.forProduct(product.id),
      imageUrl: product.image && product.image.small,
    };

    delete suggestedProduct.image;

    //3.5. Product grouping – Quick order and search related changes
    suggestedProducts.push(suggestedProduct);
    if (product.isParent)
      suggestedParentProducts.push(suggestedProduct);
    else
      suggestedRegularProducts.push(suggestedProduct);

  }

  //3.5. Product grouping – Quick order and search related changes
  if (suggestedRegularProducts.length > 0)
    sections.push({ title: 'regularProducts', suggestions: suggestedRegularProducts });

  if (suggestedParentProducts.length > 0)
    sections.push({ title: 'parentProducts', suggestions: suggestedParentProducts });

  return productsSearchSuggestionsCompleted(sections);
}
