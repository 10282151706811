exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".StyleWrapper_wrapper{--minWidth_Desktop:auto;--minWidth_Tablet:var(--minWidth_Tablet,var(--minWidth_Desktop,auto));--minWidth_Mobile:var(--minWidth_Mobile,var(--minWidth_Tablet,var(--minWidth_Desktop,auto)));min-width:var(--minWidth_Mobile,var(--minWidth_Tablet,var(--minWidth_Desktop,auto)));--minHeight_Desktop:auto;--minHeight_Tablet:var(--minHeight_Tablet,var(--minHeight_Desktop,auto));--minHeight_Mobile:var(--minHeight_Mobile,var(--minHeight_Tablet,var(--minHeight_Desktop,auto)));min-height:var(--minHeight_Mobile,var(--minHeight_Tablet,var(--minHeight_Desktop,auto)));max-width:100%}@media (min-width:env(--theme-breakpoints_Small,600)px),print{.StyleWrapper_wrapper{min-width:var(--minWidth_Tablet,var(--minWidth_Desktop,auto))}}@media (min-width:env(--theme-breakpoints_Large,1280)px),print{.StyleWrapper_wrapper{min-width:var(--minWidth_Desktop,auto)}}@media (min-width:env(--theme-breakpoints_Small,600)px),print{.StyleWrapper_wrapper{min-height:var(--minHeight_Tablet,var(--minHeight_Desktop,auto))}}@media (min-width:env(--theme-breakpoints_Large,1280)px),print{.StyleWrapper_wrapper{min-height:var(--minHeight_Desktop,auto)}}.StyleWrapper_wrapper:empty{display:none}.StyleWrapper_stretch-height,.vd-drag-helper-block .StyleWrapper_wrapper{height:100%}.StyleWrapper_stretch-width{width:100%}.StyleWrapper_flex-stretch-height,.StyleWrapper_flex-stretch-width{-webkit-flex-grow:1;flex-grow:1}@media not all and (min-resolution:.001dpcm){.StyleWrapper_flex-stretch-height{height:100%}}@media (-ms-high-contrast:active),(-ms-high-contrast:none){.StyleWrapper_wrapper{min-width:1px;min-height:1px}}", ""]);

// exports
exports.locals = {
	"wrapper": "StyleWrapper_wrapper",
	"stretch-height": "StyleWrapper_stretch-height",
	"stretchHeight": "StyleWrapper_stretch-height",
	"stretch-width": "StyleWrapper_stretch-width",
	"stretchWidth": "StyleWrapper_stretch-width",
	"flex-stretch-height": "StyleWrapper_flex-stretch-height",
	"flexStretchHeight": "StyleWrapper_flex-stretch-height",
	"flex-stretch-width": "StyleWrapper_flex-stretch-width",
	"flexStretchWidth": "StyleWrapper_flex-stretch-width"
};