exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".NewsletterSubscription_container form:only-child{margin-top:-.5rem}.NewsletterSubscription_title{margin:0 0 .5rem}.NewsletterSubscription_title+.NewsletterSubscription_description{margin-top:.5rem}.NewsletterSubscription_description{margin-bottom:.5em}.NewsletterSubscription_main-form{width:100%;display:-webkit-flex;display:flex;-webkit-flex-wrap:wrap;flex-wrap:wrap;position:relative;margin:0 -.15rem}.NewsletterSubscription_field{margin:.5rem .15rem 0;-webkit-flex:100 0 auto;flex:100 0 auto}.NewsletterSubscription_recaptcha{margin-top:1.75rem}.NewsletterSubscription_recaptcha:empty{margin-top:0}.NewsletterSubscription_recaptcha img{vertical-align:bottom;box-shadow:0 0 4px 1px rgba(0,0,0,.08)}.NewsletterSubscription_no-label .form-row-label{display:none}.NewsletterSubscription_validation{padding:.4em 0 0 .15rem;line-height:1.2em;position:absolute;left:0;top:100%;font-size:var(--theme-text_SmallerSize,12px);color:var(--theme-validation_Error_Color,#cb2245)}.NewsletterSubscription_validation svg{margin-right:.5em}.NewsletterSubscription_btn-submit{-webkit-flex:1 1 auto;flex:1 1 auto;margin:.5rem .15rem 0;word-break:break-word}@supports (-ms-ime-align:auto){.NewsletterSubscription_btn-submit{overflow-wrap:break-word}}@media (-ms-high-contrast:active),(-ms-high-contrast:none){.NewsletterSubscription_btn-submit{word-wrap:break-word}}.NewsletterSubscription_btn-submit.NewsletterSubscription_vd-mode[disabled]{background-color:var(--theme-button_BackgroundColor,#1f7bc9);color:var(--theme-button_Color,#fff)}", ""]);

// exports
exports.locals = {
	"container": "NewsletterSubscription_container",
	"title": "NewsletterSubscription_title",
	"description": "NewsletterSubscription_description",
	"main-form": "NewsletterSubscription_main-form",
	"mainForm": "NewsletterSubscription_main-form",
	"field": "NewsletterSubscription_field",
	"recaptcha": "NewsletterSubscription_recaptcha",
	"no-label": "NewsletterSubscription_no-label",
	"noLabel": "NewsletterSubscription_no-label",
	"validation": "NewsletterSubscription_validation",
	"btn-submit": "NewsletterSubscription_btn-submit",
	"btnSubmit": "NewsletterSubscription_btn-submit",
	"vd-mode": "NewsletterSubscription_vd-mode",
	"vdMode": "NewsletterSubscription_vd-mode"
};