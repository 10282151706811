import styles from './AccountMenu.module.scss';
import { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'components/primitives/dropdown';
import { useSelector } from 'react-redux';
import IconWithText from './iconWithText';
import AccountMenuItems from './accountMenuItems';
import { useOnChange } from 'utils/hooks';
import { Helmet } from 'react-helmet';
import { getThemeFontSizeClassName } from 'components/theme';
import { mobileSafariMediaQuery } from 'utils/detections';
import { mousePointerClassName } from 'components/detection';

const DesktopAccountMenuBase = ({ options, isDesignerMode, elementId }) => {
  const [dropdownOptions, setDropdownOptions] = useState({ show: false });
  const { isAuthenticated, isImpersonating, shopAccountType } = useSelector(state => state.user);
  const accountMenuId = `DesktopAccountMenu_${elementId}`;

  useOnChange(() => setDropdownOptions({ show: false }), [isAuthenticated, isImpersonating], false);

  return (
    <>
      <Helmet>
        <style>{`
          ${options.toggle_FontFamily || options.toggle_FontColor || options.toggle_AllCaps ? `
            #${accountMenuId} .dropdown-header {
              ${options.toggle_FontFamily ? `font-family: ${options.toggle_FontFamily}, sans-serif;` : ''}
              ${options.toggle_FontColor ? `color: ${options.toggle_FontColor};` : ''}
              ${options.toggle_AllCaps ? 'text-transform: uppercase;' : ''}
            }
          ` : ''}
          #${accountMenuId} .${styles.toggleContent} .${styles.userIcon} {
            color: ${options.toggle_IconColor || '#cb2245'};
          }
          #${accountMenuId}.dropdown-opened .${styles.toggleContent} .${styles.userIcon} {
            color: ${options.toggle_IconHoverColor || '#cb2245'};
          }
          #${accountMenuId}.dropdown-opened,
          #${accountMenuId}.dropdown .dropdown-body {
            background-color: ${options.content_BackgroundColor || '#fff'} !important;
            border-color: ${options.content_BorderColor || '#e6e6e6'} !important;
          }
          #${accountMenuId} .dropdown-body,
          #${accountMenuId}.dropdown-opened .dropdown-header {
            color: ${options.content_FontColor || '#333'};
          }
          #${accountMenuId} .${styles.bodyHeader},
          #${accountMenuId} .${styles.bodyFooter} {
            border-color: ${options.content_SeparatorsColor || '#e6e6e6'};
          }
          #${accountMenuId} .${styles.item} {
            color: ${options.content_LinkFontColor || '#808080'};
          }
          html.${mousePointerClassName} #${accountMenuId} .${styles.item}:hover {
            color: ${options.content_LinkHoverFontColor || '#333'};
            background-color: ${options.content_LinkHoverBackgroundColor || '#f7f7f7'};
          }
          ${mobileSafariMediaQuery} {
            #${accountMenuId} .${styles.item}:hover {
              -webkit-tap-highlight-color: ${options.content_LinkHoverFontColor || '#333'};
            }
          }
        `}</style>
      </Helmet>
      <Dropdown
        id={accountMenuId}
        bodyAlign="right"
        className={styles.dropdown}
        options={dropdownOptions}
        hideCaretDown={!options.content_ShowExpandIcon}
        header={(
          <div className={`${styles.toggleContent} ${getThemeFontSizeClassName(options.toggle_ThemeFontSize)}`}>
            <IconWithText
              presentation={options.toggle_Presentation}
              isAuthenticated={isAuthenticated}
              isImpersonating={isImpersonating}
            />
          </div>
        )}
      >
        {!isDesignerMode && (
          <div className={`${styles.body} ${getThemeFontSizeClassName(options.content_ThemeFontSize)}`}>
            <AccountMenuItems
              isAuthenticated={isAuthenticated}
              isImpersonating={isImpersonating}
              shopAccountType={shopAccountType}
            />
          </div>
        )}
      </Dropdown>
    </>
  );
};

DesktopAccountMenuBase.propTypes = {
  options: PropTypes.shape({
    toggle_Presentation: PropTypes.number.isRequired,
    toggle_IconColor: PropTypes.string,
    toggle_FontFamily: PropTypes.string,
    toggle_ThemeFontSize: PropTypes.string.isRequired,
    toggle_AllCaps: PropTypes.bool.isRequired,
    toggle_FontColor: PropTypes.string,
    content_ShowExpandIcon: PropTypes.bool.isRequired,
    content_ThemeFontSize: PropTypes.string.isRequired,
    content_FontColor: PropTypes.string,
    content_LinkFontColor: PropTypes.string,
    content_LinkHoverFontColor: PropTypes.string,
    content_LinkHoverBackgroundColor: PropTypes.string,
    content_BackgroundColor: PropTypes.string,
    content_BorderColor: PropTypes.string,
    content_SeparatorsColor: PropTypes.string,
  }).isRequired,
  isDesignerMode: PropTypes.bool,
  elementId: PropTypes.string.isRequired,
};

export default memo(DesktopAccountMenuBase);
