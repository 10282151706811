import type { Epic } from 'behavior/types';
import type { Product, CalculatedProduct } from './types';
import { ignoreElements, map, mergeMap, switchMap, takeUntil, tap, pluck, filter } from 'rxjs/operators';
import { merge, of } from 'rxjs';
import { ofType } from 'redux-observable';
import {
  LastViewedTrackingAction,
  LASTVIEWED_PRODUCTS_CLEARED,
  LASTVIEWED_PRODUCTS_REQUESTED,
  lastViewedProductsReceived,
  PRODUCT_VIEWED,
} from './actions';
import { loadCalculatedFieldsQuery, productsQuery } from './queries';
import { VIEWER_CHANGED } from 'behavior/events';
import { createApiVariables, sortProducts } from './helpers';
import { storageKey } from 'behavior/products/lastViewedTracking';

const epic: Epic<LastViewedTrackingAction> = (action$, state$, { api, localStorage }) => {
  const trackViewedProduct$ = action$.pipe(
    ofType(PRODUCT_VIEWED),
    tap(({ payload: id }) => {
      const storedProducts = localStorage.getItem<string[]>(storageKey);
      const products = storedProducts?.length
        ? [id, ...storedProducts.filter(productId => productId !== id).slice(0, 24)]
        : [id];

      localStorage.setItem(storageKey, products);
    }),
    ignoreElements(),
  );

  const clearViewedProducts$ = action$.pipe(
    ofType(LASTVIEWED_PRODUCTS_CLEARED),
    tap(_ => localStorage.setItem(storageKey, [])),
    ignoreElements(),
  );

  const reset$ = action$.pipe(
    ofType(
      LASTVIEWED_PRODUCTS_CLEARED,
      VIEWER_CHANGED,
    ),
  );

  //[147356]-3.2 Product grouping � Product list and search result page changes
  const requestProducts$ = action$.pipe(
    ofType(LASTVIEWED_PRODUCTS_REQUESTED),
      switchMap(({ payload: { skipCurrent, count } }) => {
          const variables = createApiVariables(localStorage, count, skipCurrent,'lastview');
      if (!variables)
        return of(lastViewedProductsReceived([]));

      variables.loadCategories = !!state$.value.analytics?.isTrackingEnabled;

      return api.graphApi<GeneralProductsResponse>(productsQuery, variables, { retries: 0 }).pipe(
        pluck('catalog', 'products'),
        mergeMap(result => {
          if (!result?.products.length)
            return of(lastViewedProductsReceived([]));

          const sortedProducts = sortProducts(result.products, variables)!;
          return merge(
            of(lastViewedProductsReceived(sortedProducts)),
            api.graphApi<CalculatedProductsResponse>(loadCalculatedFieldsQuery, variables).pipe(
              pluck('catalog', 'products'),
              filter(r => !!r?.products.length),
              map(r => lastViewedProductsReceived(r!.products)),
            ),
          );
        }),
        takeUntil(reset$),
      );
    },
    ),
  );

  return merge(trackViewedProduct$, clearViewedProducts$, requestProducts$);
};

export default epic;

type GeneralProductsResponse = {
  catalog: {
    products: {
      products: Product[];
    } | null;
  };
};

type CalculatedProductsResponse = {
  catalog: {
    products: {
      products: CalculatedProduct[];
    } | null;
  };
};
