import type { Section } from './types';
import { SearchSuggestionsAction, SEARCH_PRODUCT_SUGGESTIONS_COMPLETED, SEARCH_PRODUCT_SUGGESTIONS_CLEARED } from './actions';

type SearchSuggestionsState = {
    sections: Section[]; //3.5. Product grouping � Quick order and search related changes
};
const initialState: SearchSuggestionsState = {
    sections: [], //3.5. Product grouping � Quick order and search related changes
};

export default (state = initialState, action: SearchSuggestionsAction) => {
  switch (action.type) {
    case SEARCH_PRODUCT_SUGGESTIONS_COMPLETED:
      if (!action.payload.length)
        return initialState;

      //3.5. Product grouping � Quick order and search related changes
      return { sections: action.payload };
    case SEARCH_PRODUCT_SUGGESTIONS_CLEARED:
      return initialState;
    default:
      return state;
  }
};
