import {
  updateProductCalculatedFieldsQuery,
  reviewsQuery,
  addReview,
  requestVolumePricesQuery,
  salesAgreementQuery,
  selectAttributesQuery,
  createProductPageQuery,
} from './queries';
import {
  UPDATE_PRODUCT_CALCULATED_FIELDS,
  SELECT_ATTRIBUTES,
  productCalculatedFieldsLoaded,
  REVIEWS_REQUESTED,
  reviewsReceived,
  REVIEW_SUBMITTED,
  reviewProcessed,
  VOLUME_PRICES_REQUESTED,
  volumePriceReceived,
  SALES_AGREEMENT_REQUESTED,
  receiveSalesAgreement,
  updateAttributes,
  UPDATE_CHILD_PRODUCT,
  childUpdateDataReceived,
  CLEAR_ATTRIBUTE_SELECTION,
  updatedPatentDataReceived,
} from './actions';
import { switchMap, map, takeUntil, exhaustMap, pluck, filter, mergeMap, catchError, startWith } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { rewriteTo } from 'behavior/routing';
import { LOCATION_CHANGED } from 'behavior/events';
import { routesBuilder } from 'routes';
import { retryWithToast, catchApiErrorWithToast } from 'behavior/errorHandling';
import { EMPTY, merge, of } from 'rxjs';
import { resetCaptcha } from 'behavior/captcha';
import { unlockForm, FormName } from 'behavior/pages';
import { requestAbility } from 'behavior/user/epic';
import { AbilityState, AbilityTo } from 'behavior/user/constants';
import { ProductSpecificationFilter } from 'behavior/products/product';
import { ProductMediaType, Presets } from './constants';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { parseVideoData } from 'utils/video';
import { navigateTo } from 'behavior/events';

const productEpic = (action$, state$, dependencies) => {
  const { api, logger, scope } = dependencies;

  const locationChanged$ = action$.pipe(ofType(LOCATION_CHANGED));

  const onFieldsRequested$ = action$.pipe(
    ofType(UPDATE_PRODUCT_CALCULATED_FIELDS),
    switchMap(action => api.graphApi(updateProductCalculatedFieldsQuery, action.payload).pipe(
      map(mapResponseToAction),
      retryWithToast(action$, logger),
      takeUntil(locationChanged$),
    )),
  );

  const onReviewsRequested$ = action$.pipe(
    ofType(REVIEWS_REQUESTED),
    exhaustMap(action => api.graphApi(reviewsQuery, action.payload).pipe(
      pluck('catalog', 'products', 'products', '0', 'reviews'),
      filter(r => r && r.list && r.list.length),
      map(r => reviewsReceived(r.list)),
      takeUntil(locationChanged$),
    )),
  );

  const reviewProcessedAction = reviewProcessed(true);
  const onReviewSubmitted$ = action$.pipe(
    ofType(REVIEW_SUBMITTED),
    exhaustMap(action => api.graphApi(addReview, { data: action.payload }).pipe(
      mergeMap(_ => [reviewProcessedAction, resetCaptcha(FormName.Review), unlockForm(FormName.Review)]),
      catchApiErrorWithToast(['INVALID_INPUT'], of(resetCaptcha(FormName.Review), unlockForm(FormName.Review))),
      retryWithToast(action$, logger, _ => of(resetCaptcha(FormName.Review), unlockForm(FormName.Review))),
      takeUntil(locationChanged$),
    )),
  );

  const onVolumePricesRequested$ = action$.pipe(
    ofType(VOLUME_PRICES_REQUESTED),
    switchMap(action => api.graphApi(requestVolumePricesQuery, action.payload).pipe(
      map(data => {
        const volumePrices = data.catalog.volumePrices;
        const { variantId, uomId } = action.payload;

        return volumePriceReceived({ prices: volumePrices, variantId, uomId });
      }),
      retryWithToast(action$, logger),
      takeUntil(locationChanged$),
    )),
  );

  const onAgreementTermsRequested$ = action$.pipe(
    ofType(SALES_AGREEMENT_REQUESTED),
    pluck('payload'),
    switchMap(({ agreementId, productId }) => api.graphApi(salesAgreementQuery, { agreementId, productIds: [productId] }).pipe(
      pluck('salesAgreements'),
      switchMap(({ agreement, linesAvailability }) => requestAbility(AbilityTo.ViewUnitOfMeasure, state$, dependencies).pipe(
        map(canViewUomAbility => {
          const { uom, uoms } = state$.value.page.product;
          return receiveSalesAgreement(
            productId,
            agreement,
            linesAvailability,
            canViewUomAbility === AbilityState.Available,
            state$.value.settings.product.allowUOMSelection,
            uom,
            uoms,
          );
        }),
      )),
      catchError(_ => {
        logger.warn('Could not retrieve sales agreement terms for the product. '
          + 'The agreement is specified in the basket but the server returned no agreement terms. The server might be in offline mode.');
        return EMPTY;
      }),
      takeUntil(locationChanged$),
    )),
  );

  //3.3 Product grouping – Product group page changes
  const selectAttributesRequest$ = action$.pipe(
    ofType(SELECT_ATTRIBUTES),
    switchMap(action => api.graphApi(selectAttributesQuery, action.payload).pipe(
      mergeMap(data => {
        const { attributes, url } = state$.value.page.product;
        return of(mapAttributesToAction(data, attributes, url, action.payload.options.isChangingPreviouslyChangeAttribute), unsetLoadingIndicator());
      }),
      retryWithToast(action$, logger, () => unsetLoadingIndicator()),
      takeUntil(locationChanged$),
      startWith(setLoadingIndicator()),
    )),
  );

  //3.3 Product grouping – Product group page changes
  const updateProductRequest$ = action$.pipe(
    ofType(UPDATE_CHILD_PRODUCT),
    switchMap(action => api.graphApi(createProductPageQuery({ scope, isInsiteEditor: state$.value.insiteEditor.initialized }),
      {
        productId: action.payload.selectedChildProductId,
        loadChildPage: true,
        specificationFilter: ProductSpecificationFilter.ForDetails,
        detailsPreset: Presets.Details,
        loadRelatedProductsCategories: state$.value.analytics && state$.value.analytics.isTrackingEnabled,
        loadUom: state$.value.analytics && state$.value.analytics.isTrackingEnabled,
      }).pipe(
        map(data => {
          return childUpdateDataReceived(data.pages.product);
        }),
        retryWithToast(action$, logger),
        takeUntil(locationChanged$),
      )),
  );

  //3.3 Product grouping – Product group page changes
  const clearAttributeSelection$ = action$.pipe(
    ofType(CLEAR_ATTRIBUTE_SELECTION),
    switchMap(action => api.graphApi(createProductPageQuery({ scope, isInsiteEditor: state$.value.insiteEditor.initialized }),
      {
        productId: action.payload,
        specificationFilter: ProductSpecificationFilter.ForDetails,
        detailsPreset: Presets.Details,
        loadRelatedProductsCategories: state$.value.analytics && state$.value.analytics.isTrackingEnabled,
        loadUom: state$.value.analytics && state$.value.analytics.isTrackingEnabled,
      }).pipe(
        mergeMap(data => {
          processMedia(data);
          return of(updatedPatentDataReceived(data.pages.product), unsetLoadingIndicator());
        }),
        retryWithToast(action$, logger),
        takeUntil(locationChanged$),
        startWith(setLoadingIndicator()),
      )),
  );

  return merge(
    onFieldsRequested$,
    onReviewsRequested$,
    onReviewSubmitted$,
    onVolumePricesRequested$,
    onAgreementTermsRequested$,
    selectAttributesRequest$,
    updateProductRequest$,
    clearAttributeSelection$,
  );
};

export default productEpic;

function mapResponseToAction(data) {
  const product = data.catalog.products.products[0];
  if (!product)
    return rewriteTo(routesBuilder.forNotFound());

  return productCalculatedFieldsLoaded(product);
}

//3.3 Product grouping – Product group page changes
function mapAttributesToAction(data, productAttributes, url, isChangingPreviouslyChangeAttribute = false) {

  const attributes = data.catalog.selectAttributes.attributes;
  const autoChangedAttributes = data.catalog.selectAttributes.autoChangedAttributes;
  const selectedChildProductId = data.catalog.selectAttributes.selectedChildProductId;
  const immediateAttributeIdToSelected = data.catalog.selectAttributes.immediateAttributeIdToSelected;

  for (const attr of productAttributes) {
    const attribute = attributes.find(a => a.attributeId === attr.attributeId);

    if (attribute != null) {

      //This if block run if the user change previously selected attributes
      if (isChangingPreviouslyChangeAttribute) {

        const autoChangedAttribute = autoChangedAttributes.find(a => a.attributeId === attr.attributeId);
        if (autoChangedAttribute != null) {
          attr.attributeValues.forEach(value => {
            const isValuePresent = autoChangedAttribute.attributeValues.some(val => val.attributeValue === value.attributeValue);
            if (isValuePresent)
              value.disabled = false;
            else
              value.disabled = true;
          });
        }

      }

      attr.selectedvalue = attribute.selectedvalue;

      //if the attribute is immediateAttributeIdToSelected or if there are only one set of attribute values and selectedvalue is null
      if (immediateAttributeIdToSelected === attribute.attributeId || (attr.attributeValues.length === 1 && attribute.selectedvalue != null)) {
        attr.attributeValues.forEach(value => {
          const isValuePresent = attribute.attributeValues.some(val => val.attributeValue === value.attributeValue);
          if (isValuePresent)
            value.disabled = false;
          else
            value.disabled = true;
        });
      }
    }

  }

  if (selectedChildProductId != null)
    return navigateTo(routesBuilder.forProduct(selectedChildProductId, null, null, true), url);

  return updateAttributes(productAttributes);
}

//3.3 Product grouping – Product group page changes
function processMedia(data) {
  const page = data && data.pages.product;
  if (page)
    page.product.media = page.product.media
      .map(media => {
        if (media.type === ProductMediaType.Video) {
          const videoData = parseVideoData(media.url);

          return {
            type: media.type,
            videoData,
          };
        }

        return media;
      })
      .filter(media => {
        if (media.type === ProductMediaType.Video)
          return !!media.videoData;

        return media.small || media.medium || media.large;
      });
}