//TICKET 169238 - Reset the quick order form after adding the product to the basket
import { EffectCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

export default function onSubmittingQuickOrder(onChanged: EffectCallback): void {
    const selectedProduct = useSelector(state => state.productSelector.selectedProduct);
    useEffect(() => {
        if (selectedProduct == null)
            onChanged();
    }, [selectedProduct]);
}
