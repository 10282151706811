import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { StyleContext } from 'isomorphic-style-loader';
import { Helmet } from 'react-helmet';
import { primaryStyleSheets } from './constants';
import { noop } from 'rxjs';

const StyleProvider = ({ children, insertCss: _insertCss }) => {
  const { version, values: themeValues } = useSelector(state => state.theme);

  const context = useMemo(() => {
    if (!version)
      return { insertCss: noop };

    const prepareCss = replaceCss.bind(null, themeValues || {});
    return {
      insertCss: styles => _insertCss(styles, { prefix: version, prepareCss, priorities: primaryStyleSheets }),
    };
  }, [version, themeValues]);

  const rootVars = useMemo(() => {
    if (!themeValues)
      return;

    let vars = '';
    for (const name in themeValues) {
      const value = themeValues[name];
      vars += `--theme-${name}:${value};`;

      // @deprecated: The field will be removed after 2022-11-19. Old theme variable name used for compatibility with addons.
      vars += `--${name}:${value};`;

      // @deprecated: The field will be removed after 2022-11-19. Old mapped theme variable name used for compatibility with addons.
      const mappedName = mapping[name];
      if (mappedName)
        vars += `--${mappedName}:${value};`;
    }

    if (!vars)
      return;

    return (
      <Helmet>
        <style>{`:root{${vars}}`}</style>
      </Helmet>
    );
  }, [themeValues]);

  return (
    <>
      {rootVars}
      <StyleContext.Provider value={context}>
        {children}
      </StyleContext.Provider>
    </>
  );
};

StyleProvider.propTypes = {
  children: PropTypes.node.isRequired,
  insertCss: PropTypes.func.isRequired,
};

export default StyleProvider;

// @deprecated: The field will be removed after 2022-11-19. RegExp for old breakpoints theme vars used for compatibility with addons.
const replaceableBreakpointValue = /var\(--(?:breakpoints_Small|breakpoints_Medium|breakpoints_Large|breakpoints_ExtraLarge),\s*(?<defaultValue>[^)]+\)?)\)/g;
const replaceableVariableRegex = /env\(--theme-(?<name>[^,\s]+),\s*(?<defaultValue>[^)]+\)?)\)/g;

function replaceCss(themeValues, css) {
  return css
    .replace(replaceableBreakpointValue, (_, defaultValue) => defaultValue)
    .replace(replaceableVariableRegex, (_, name, defaultValue) => {
      return themeValues[name] ?? defaultValue;
    });
}

// @deprecated: The field will be removed after 2022-11-19. Mapping for old theme variables used for compatibility with addons.
const mapping = {
  h_FontFamily: 'headingText_FontFamily',
  h1_Size: 'headingText_FontSizeH1',
  h1_TextTransform: 'headingText_H1_TextTransform',
  h2_Size: 'headingText_FontSizeH2',
  h2_TextTransform: 'headingText_H2_TextTransform',
  h3_Size: 'headingText_FontSizeH3',
  h3_TextTransform: 'headingText_H3_TextTransform',
  h4_Size: 'headingText_FontSizeH4',
  h4_TextTransform: 'headingText_H4_TextTransform',
  h5_Size: 'headingText_FontSizeH5',
  h5_TextTransform: 'headingText_H5_TextTransform',
  text_FontFamily: 'normalText_FontFamily',
  text_LineHeight: 'normalText_LineHeight',
  text_BiggerSize: 'normalText_BiggerFontSize',
  text_Size: 'normalText_FontSize',
  text_SmallerSize: 'normalText_SmallerFontSize',
  text_SmallestSize: 'normalText_SmallestFontSize',
  message_Size: 'messages_FontSize',
  tabHeader_Size: 'tabHeader_FontSize',
  searchBox_Size: 'searchBox_FontSize',
  popup_Title_Size: 'popup_Title_FontSize',
  cart_ProductTitle_Size: 'cart_ProductTitle_FontSize',
  cart_TotalRow_Size: 'cart_TotalRow_FontSize',
  pdp_Title_Size: 'productPage_Title_FontSize',
  pdp_Mobile_Title_Size: 'productPage_Mobile_Title_FontSize',
  primaryPrice_Size: 'primaryPrice_FontSize',
  listPrice_Size: 'listPrice_FontSize',
  listPrice_DiscountPercentage_Size: 'listPrice_DiscountPercentage_FontSize',
  listPrice_DiscountAmount_Size: 'listPrice_DiscountAmount_FontSize',
  variantMatrixHeader_Size: 'variantMatrixHeaders_FontSize',
  tabHeader_Big_Size: 'tabHeader_Big_FontSize',
  secondaryPrice_Size: 'secondaryPrice_FontSize',
  plp_Title_Size: 'productList_Title_FontSize',
  plp_FacetsTitle_Size: 'productList_FacetsTitle_FontSize',
  bulkPrice_Size: 'bulkPrice_FontSize',
  cookiebar_CookiesPolicy_Size: 'cookiebar_CookiesPolicy_FontSize',
  button_FontFamily: 'buttons_FontFamily',
  button_Normal_Size: 'buttons_Normal_FontSize',
  button_Normal_TextTransform: 'buttons_Normal_TextTransform',
  button_Medium_Size: 'buttons_Medium_FontSize',
  button_Medium_TextTransform: 'buttons_Medium_TextTransform',
  button_Big_Size: 'buttons_Big_FontSize',
  button_Big_TextTransform: 'buttons_Big_TextTransform',
  dH_FontFamily: 'displayHeadingText_FontFamily',
  dH1_Size: 'displayHeadingText_FontSizeDH1',
  dH1_TextTransform: 'displayHeadingText_DH1_TextTransform',
  dH2_Size: 'displayHeadingText_FontSizeDH2',
  dH2_TextTransform: 'displayHeadingText_DH2_TextTransform',
  dH3_Size: 'displayHeadingText_FontSizeDH3',
  dH3_TextTransform: 'displayHeadingText_DH3_TextTransform',
  text_Color: 'normalText_FontColor',
  lighterText_Color: 'lighterText_FontColor',
  lightestText_Color: 'lightestText_FontColor',
  darkestText_Color: 'darkestText_FontColor',
  link_Color: 'hyperlink_FontColor',
  link_Hover_Color: 'hyperlink_Hover_FontColor',
  link_Disabled_Color: 'hyperlink_Disabled_FontColor',
  h_Color: 'headingText_FontColor',
  dH_Color: 'displayHeadingText_FontColor',
  regularTitle_Color: 'regularTitle_FontColor',
  button_Color: 'buttons_FontColor',
  button_BackgroundColor: 'buttons_BackgroundColor',
  button_Hover_BackgroundColor: 'buttons_Hover_BackgroundColor',
  button_BottomBorderColor: 'buttons_BottomBorderColor',
  button_Hover_BottomBorderColor: 'buttons_Hover_BottomBorderColor',
  button_Action_BackgroundColor: 'buttons_Action_BackgroundColor',
  button_Action_Hover_BackgroundColor: 'buttons_Action_Hover_BackgroundColor',
  button_Action_BottomBorderColor: 'buttons_Action_BottomBorderColor',
  button_Action_Hover_BottomBorderColor: 'buttons_Action_Hover_BottomBorderColor',
  button_Disabled_Color: 'buttons_Disabled_FontColor',
  button_Disabled_BackgroundColor: 'buttons_Disabled_BackgroundColor',
  button_Disabled_BottomBorderColor: 'buttons_Disabled_BottomBorderColor',
  button_Back_Color: 'buttons_Back_FontColor',
  button_Back_Hover_Color: 'buttons_Back_Hover_FontColor',
  message_Color: 'messages_FontColor',
  message_BackgroundColor: 'messages_BackgroundColor',
  message_Error_Color: 'messages_Error_FontColor',
  message_Error_BackgroundColor: 'messages_Error_BackgroundColor',
  message_Error_IconColor: 'messages_Error_IconColor',
  message_Confirm_Color: 'messages_Confirm_FontColor',
  message_Confirm_BackgroundColor: 'messages_Confirm_BackgroundColor',
  message_Confirm_IconColor: 'messages_Confirm_IconColor',
  message_Info_Color: 'messages_Info_FontColor',
  message_Info_BackgroundColor: 'messages_Info_BackgroundColor',
  message_Info_IconColor: 'messages_Info_IconColor',
  message_Alert_Color: 'messages_Alert_FontColor',
  message_Alert_BackgroundColor: 'messages_Alert_BackgroundColor',
  message_Alert_IconColor: 'messages_Alert_IconColor',
  sideLink_Facets_Color: 'sideLinks_Facets_FontColor',
  sideLink_News_Color: 'sideLinks_News_FontColor',
  sideLink_MyAccount_Color: 'sideLinks_MyAccount_FontColor',
  pagerLink_Color: 'pagerLinks_FontColor',
  pagerLink_Selected_Color: 'pagerLinks_Selected_FontColor',
  breadcrumb_Color: 'breadcrumb_FontColor',
  breadcrumb_LastItem_Color: 'breadcrumb_LastItem_FontColor',
  input_Color: 'inputs_FontColor',
  input_Placeholder_Color: 'inputs_Placeholder_FontColor',
  input_BackgroundColor: 'inputs_BackgroundColor',
  input_Focus_BackgroundColor: 'inputs_Focus_BackgroundColor',
  input_Error_BackgroundColor: 'inputs_Error_BackgroundColor',
  input_BottomBorderColor: 'inputs_BottomBorderColor',
  input_Focus_BottomBorderColor: 'inputs_Focus_BottomBorderColor',
  input_Error_BottomBorderColor: 'inputs_Error_BottomBorderColor',
  input_SelectHover_BackgroundColor: 'inputs_SelectHover_BackgroundColor',
  datePicker_Selected_Color: 'datePicker_Selected_FontColor',
  table_BorderColor: 'tables_BorderColor',
  table_Footer_BackgroundColor: 'tables_Footer_BackgroundColor',
  table_Header_Color: 'table_Header_FontColor',
  table_Cell_Color: 'table_Cell_FontColor',
  propertyTable_Name_Color: 'propertyTable_Name_FontColor',
  propertyTable_Value_Color: 'propertyTable_Value_FontColor',
  tabHeader_Inactive_Color: 'tabHeader_Inactive_FontColor',
  tabHeader_Hover_Color: 'tabHeader_Hover_FontColor',
  tabHeader_Active_Color: 'tabHeader_Active_FontColor',
  searchBox_Placeholder_Color: 'searchBox_Placeholder_FontColor',
  status_Ok_Color: 'status_Ok_FontColor',
  status_Pending_Color: 'status_Pending_FontColor',
  status_Failed_Color: 'status_Failed_FontColor',
  cookiebar_CookiesPolicy_Color: 'cookiebar_CookiesPolicy_FontColor',
  primaryPrice_Color: 'primaryPrice_FontColor',
  listPrice_Color: 'listPrice_FontColor',
  listPrice_DiscountPercentage_Color: 'listPrice_DiscountPercentage_FontColor',
  listPrice_DiscountAmount_Color: 'listPrice_DiscountAmount_FontColor',
  volumePrices_Discount_Color: 'volumePrices_Discount_FontColor',
  variantMatrixHeader_Color: 'variantMatrixHeaders_FontColor',
  variantMatrixHeader_FontFamily: 'variantMatrixHeaders_FontFamily',
  secondaryPrice_Color: 'secondaryPrice_FontColor',
  stockAmount_InStock_Color: 'stockAmount_InStock_FontColor',
  stockAmount_LowStock_Color: 'stockAmount_LowStock_FontColor',
  stockAmount_OutOfStock_Color: 'stockAmount_OutOfStock_FontColor',
  bulkPrice_Color: 'bulkPrice_FontColor',
  bulkPrice_Highlight_Color: 'bulkPrice_Highlight_FontColor',
  cart_ProductTitle_Color: 'cart_ProductTitle_FontColor',
  cart_LineDiscount_Color: 'cart_LineDiscount_FontColor',
  cart_ExtendedText_Color: 'cart_ExtendedText_FontColor',
  checkout_FreeServiceCost_Color: 'checkout_Free_Service_Costs_FontColor',
  header_Color: 'header_FontColor',
  header_Dropdown_BackgroundColor: 'header_Dropdowns_BackgroundColor',
  footer_Color: 'footer_FontColor',
  product_NoImage_SM: 'product_NoImage_Small',
  product_NoImage_MD: 'product_NoImage_Medium',
  product_NoImage_LG: 'product_NoImage_Large',
  thumbnail_Active_BorderColor: 'thumbnails_Active_BorderColor',
  toast_Success_Color: 'toast_Success_FontColor',
  toast_Success_CountdownColor: 'toast_Success_CountdownIndicatorColor',
  toast_Success_CountdownBackgroundColor: 'toast_Success_CountdownIndicatorBackgroundColor',
  toast_Error_Color: 'toast_Error_FontColor',
  toast_Error_CountdownColor: 'toast_Error_CountdownIndicatorColor',
  toast_Error_CountdownBackgroundColor: 'toast_Error_CountdownIndicatorBackgroundColor',
  toast_Warning_Color: 'toast_Warning_FontColor',
  toast_Warning_CountdownColor: 'toast_Warning_CountdownIndicatorColor',
  toast_Warning_CountdownBackgroundColor: 'toast_Warning_CountdownIndicatorBackgroundColor',
  toast_Info_Color: 'toast_Info_FontColor',
  toast_Info_CountdownColor: 'toast_Info_CountdownIndicatorColor',
  toast_Info_CountdownBackgroundColor: 'toast_Info_CountdownIndicatorBackgroundColor',
  validation_Error_Color: 'validation_Error_FontColor',
  validation_Success_Color: 'validation_Success_FontColor',
  validation_Warning_Color: 'validation_Warning_FontColor',
};
