import { combineEpics } from 'utils/rxjs';
import appEpic from 'behavior/app/epic';
import settingsEpic from 'behavior/settings/epic';
import sanaTextEpic from 'behavior/sanaText/epic';
import userEpic from 'behavior/user/epic';
import feedbackEpic from 'behavior/pages/contactUs/epic';
import navigationEpic from 'behavior/navigation/epic';
import newsHeadlinesEpic from 'behavior/newsHeadlines/epic';
import newsEpic from 'behavior/pages/news/epic';
import changePasswordEpic from 'behavior/pages/changePassword/epic';
import newsletterEpic from 'behavior/newsletter/epic';
import pagesEpic from 'behavior/pages/epic';
import forgotPasswordEpic from 'behavior/pages/forgotPassword/epic';
import localizationEpic from 'behavior/localization/epic';
import themeEpic from 'behavior/theme/epic';
import searchEpic from 'behavior/products/search/suggestions/epic';
import productEpic from 'behavior/pages/product/epic';
import representationPageEpic from 'behavior/pages/representation/epic';
import connectionEpic from 'behavior/connection/epic';
import basketEpic from 'behavior/basket/epic';
import basketPageEpic from 'behavior/pages/basket/epic';
import productListEpic from 'behavior/pages/productList/epic';
import childMenuItemsEpic from 'behavior/content/childMenuItems/epic';
import sortableFieldsEpic from 'behavior/products/search/sortableFields/epic';
import lastViewedProductsPageEpic from 'behavior/pages/productList/lastViewed/epic';
import lastViewedProductsEpic from 'behavior/products/lastViewedTracking/epic';
import orderTemplatesEpic from 'behavior/pages/orderTemplates/epic';
import salesAgreementsPageEpic from 'behavior/pages/salesAgreements/epic';
import documentsEpic from 'behavior/pages/documents/epic';
import documentEpic from 'behavior/pages/document/epic';
import productConfiguratorEpic from 'behavior/productConfigurator/epic';
import productComparisonEpic from 'behavior/productComparison/epic';
import checkoutOffersEpic from 'behavior/checkoutOffers/epic';
import resetPasswordEpic from 'behavior/pages/resetPassword/epic';
import subAccountsEpic from 'behavior/pages/subAccounts/epic';
import productSetsEpic from 'behavior/content/productSets/epic';
import visualDesignerEpic from 'behavior/visualDesigner/epic';
import insiteEditorEpic from 'behavior/insiteEditor/epic';
import checkoutPageEpic from 'behavior/pages/checkout/epic';
import analyticsEpic from 'behavior/analytics/epic';
import registrationEpic from 'behavior/pages/registration/epic';
import editProfileEpic from 'behavior/pages/editProfile/epic';
import impersonationEpic from 'behavior/tools/impersonation/epic';
import adminTextsEpic from 'behavior/adminTexts/epic';
import orderAuthorizationsEpic from 'behavior/pages/orderAuthorizations/epic';
import invoicePaymentEpic from 'behavior/pages/invoicePayment/epic';
import salesAgreementsEpic from 'behavior/salesAgreements/epic';
import productSelectorEpic from 'behavior/productSelector/epic';
import createDocFreeReturnOrderEpic from 'behavior/pages/createDocFreeReturnOrder/epic';
import createDocBasedReturnOrderEpic from 'behavior/pages/createDocBasedReturnOrder/epic';
import orderPaymentEpic from 'behavior/pages/orderPayment/epic';
import wishListEpic from 'behavior/wishList/epic';
import previewEpic from 'behavior/preview/epic';
import headerEpic from 'behavior/header/epic';
import footerEpic from 'behavior/footer/epic';

export default combineEpics(
  appEpic,
  checkoutOffersEpic,
  settingsEpic,
  sanaTextEpic,
  userEpic,
  newsHeadlinesEpic,
  navigationEpic,
  newsEpic,
  changePasswordEpic,
  localizationEpic,
  pagesEpic,
  forgotPasswordEpic,
  feedbackEpic,
  newsletterEpic,
  themeEpic,
  searchEpic,
  productEpic,
  productListEpic,
  representationPageEpic,
  basketEpic,
  basketPageEpic,
  connectionEpic,
  childMenuItemsEpic,
  sortableFieldsEpic,
  lastViewedProductsPageEpic,
  lastViewedProductsEpic,
  orderTemplatesEpic,
  salesAgreementsPageEpic,
  documentsEpic,
  documentEpic,
  productConfiguratorEpic,
  productComparisonEpic,
  resetPasswordEpic,
  subAccountsEpic,
  productSetsEpic,
  visualDesignerEpic,
  insiteEditorEpic,
  checkoutPageEpic,
  analyticsEpic,
  registrationEpic,
  editProfileEpic,
  impersonationEpic,
  adminTextsEpic,
  orderAuthorizationsEpic,
  invoicePaymentEpic,
  salesAgreementsEpic,
  productSelectorEpic,
  createDocFreeReturnOrderEpic,
  createDocBasedReturnOrderEpic,
  orderPaymentEpic,
  wishListEpic,
  previewEpic,
  headerEpic,
  footerEpic,
);
