//TICKET 147361 - 3.9 Show/hide sales price in the webshop (134939 & 134942)
import { SimpleText } from 'components/sanaText';
import {changePricePresentation} from 'behavior/user';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

const PricePresentation = () => {

  const { canChangePricePresentation, showBothPrices } = useSelector(({ user }) => ({
    canChangePricePresentation: user.canChangePricePresentation,
    showBothPrices: user.showBothPrices
  }), shallowEqual);

  const dipatch = useDispatch();

  if(!canChangePricePresentation)
    return null;

  return (
    <div className='btn_back'>
      <button className='btn_edit' onClick={() => dipatch(changePricePresentation(!showBothPrices))}>
        {!showBothPrices 
          ? <SimpleText textKey="PricePresentation_ShowBothPricesText" /> 
          : <SimpleText textKey="PricePresentation_ShowRetailpriceText" />
        }
      </button>
    </div>
  );
};

export default PricePresentation;