import type { Section } from './types';

export const SEARCH_PRODUCT_SUGGESTIONS = 'PRODUCT/SEARCH/SUGGESTION' as const;

//147356 - 3.2 Product grouping � Product list and search result page changes
export const searchProductsSuggestions = (keywords: string, searchType = 'parentsonly', isMainSearch = true) => ({
  type: SEARCH_PRODUCT_SUGGESTIONS,
  payload: { keywords, searchType, isMainSearch },
});

export const SEARCH_PRODUCT_SUGGESTIONS_COMPLETED = 'PRODUCT/SEARCH/SUGGESTION/COMPLETED' as const;
export const productsSearchSuggestionsCompleted = (sections: Section[]) => ({
  type: SEARCH_PRODUCT_SUGGESTIONS_COMPLETED,
    payload: sections,
});

export const SEARCH_PRODUCT_SUGGESTIONS_CLEARED = 'PRODUCT/SEARCH/SUGGESTION/CLEARED' as const;
export const clearProductsSuggestions = () => ({
  type: SEARCH_PRODUCT_SUGGESTIONS_CLEARED,
});

export type SearchSuggestionsAction = ReturnType<
  | typeof searchProductsSuggestions
  | typeof productsSearchSuggestionsCompleted
  | typeof clearProductsSuggestions
>;
