exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".LoadingIndicator_loading-indicator{position:fixed;top:0;left:0;width:100%;height:3px;overflow:hidden;transition:opacity .2s linear;background-color:#f0f0f0;z-index:9800000}.LoadingIndicator_loading-indicator .LoadingIndicator_indicator-line{position:absolute;top:0;left:0;right:0;bottom:0;-webkit-transform:translateX(-20%);transform:translateX(-20%)}.LoadingIndicator_loading-indicator .LoadingIndicator_indicator-line:before{content:\"\";position:absolute;top:0;left:0;bottom:0;width:20%;background-color:var(--theme-loadingIndicator_Color,#cb2245)}.LoadingIndicator_loading-indicator.LoadingIndicator_animate .LoadingIndicator_indicator-line{-webkit-animation:LoadingIndicator_slideRight 1.5s ease-out infinite;animation:LoadingIndicator_slideRight 1.5s ease-out infinite}.LoadingIndicator_loading-indicator.LoadingIndicator_animate .LoadingIndicator_indicator-line:before{-webkit-animation:LoadingIndicator_pulse 1.5s linear infinite;animation:LoadingIndicator_pulse 1.5s linear infinite}@media print{.LoadingIndicator_loading-indicator{display:none}}@-webkit-keyframes LoadingIndicator_slideRight{0%{-webkit-transform:translateX(-20%);transform:translateX(-20%)}to{-webkit-transform:translateX(100%);transform:translateX(100%)}}@keyframes LoadingIndicator_slideRight{0%{-webkit-transform:translateX(-20%);transform:translateX(-20%)}to{-webkit-transform:translateX(100%);transform:translateX(100%)}}@-webkit-keyframes LoadingIndicator_pulse{0%,10%{-webkit-transform:scaleX(1);transform:scaleX(1)}38%{-webkit-transform:scaleX(2.5);transform:scaleX(2.5)}66%,to{-webkit-transform:scaleX(1);transform:scaleX(1)}}@keyframes LoadingIndicator_pulse{0%,10%{-webkit-transform:scaleX(1);transform:scaleX(1)}38%{-webkit-transform:scaleX(2.5);transform:scaleX(2.5)}66%,to{-webkit-transform:scaleX(1);transform:scaleX(1)}}", ""]);

// exports
exports.locals = {
	"loading-indicator": "LoadingIndicator_loading-indicator",
	"loadingIndicator": "LoadingIndicator_loading-indicator",
	"indicator-line": "LoadingIndicator_indicator-line",
	"indicatorLine": "LoadingIndicator_indicator-line",
	"animate": "LoadingIndicator_animate",
	"slideRight": "LoadingIndicator_slideRight",
	"pulse": "LoadingIndicator_pulse"
};