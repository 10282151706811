import styles from '../AccountMenu.module.scss';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import { WishlistLink, MyAccountLink } from 'components/primitives/links';
import { useSelector } from 'react-redux';
import LogoutButton from './LogoutButton';
import PricePresentation from '../../pricePresentation/PricePresentation';

const CustomerMenuItems = ({ logoutDelay }) => {
  const name = useSelector(state => state.user.name);

  return (
    <>
      <div className={styles.bodyHeader}>
        <span className="user-name"><SimpleText textKey="Login_Welcome" /> <b>{name}</b></span>
      </div>
      <MyAccountLink className={styles.item} />
      <WishlistLink className={styles.item} />

      {/* TICKET 147361 - 3.9 Show/hide sales price in the webshop (134939 & 134942) */}
      <PricePresentation />

      <LogoutButton logoutDelay={logoutDelay} />
    </>
  );
};

CustomerMenuItems.propTypes = {
  logoutDelay: PropTypes.number,
};

export default CustomerMenuItems;
