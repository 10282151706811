import { createProductPageQuery } from './queries';
import { tap, first, switchMap, pluck } from 'rxjs/operators';
import { of } from 'rxjs';
import { initComponent } from 'behavior/pages/helpers';
import { parseContent } from 'behavior/content';
import { ProductSpecificationFilter } from 'behavior/products/product';
import { routesBuilder } from 'routes';
import { ProductMediaType, Presets } from './constants';
import { PageComponentNames } from '../componentNames';
import { printModeEnabled } from 'behavior/printMode';
import { parseVideoData } from 'utils/video';

export default ({ params: { id, agreementLine: salesAgreementLineId, loadChildPage } }, state$, { api, scope }) => id
  ? state$.pipe(
    first(state => state.settings.loaded),
    switchMap(state => {
      const options = { scope, isInsiteEditor: state.insiteEditor.initialized };
      const query = createProductPageQuery(options);
      const isTrackingEnabled = state.analytics && state.analytics.isTrackingEnabled;
      const variables = {
        productId: id,
        loadChildPage,
        specificationFilter: ProductSpecificationFilter.ForDetails,
        detailsPreset: Presets.Details,
        loadRelatedProductsCategories: isTrackingEnabled,
        loadUom: isTrackingEnabled,
      };

      const isPrintMode = printModeEnabled(state.routing);

      return api.graphApi(query, variables).pipe(
        pluck('pages', 'product'),
        initComponent(PageComponentNames.Product),
        tap(result => parsePageContent(result, isPrintMode)),
        tap(result => {
          const page = result && result.page;
          if (page && !!salesAgreementLineId)
            page.salesAgreement = { preSelectedLine: { id: salesAgreementLineId } };
        }),
        tap(processMedia),
        tap(result => copyProductFromState(result, state)),
      );
    }),
  )
  : of(null);

function parsePageContent(result, isPrintMode) {
  const page = result && result.page;
  if (!page)
    return;

  if (page.middleContent)
    page.middleContent = parseContent(page.middleContent);

  if (page.footerContent)
    page.footerContent = parseContent(page.footerContent);

  parseRelatedProducts(page.product);

  // Prevent page indexing by robots in case sorting is present in URL.
  page.index = !isPrintMode;
  page.isPrintMode = isPrintMode;
}

function parseRelatedProducts(product) {
  if (!product || !product.relatedProductGroups)
    return;

  for (const group of product.relatedProductGroups)
    for (const relatedProduct of group.products)
      relatedProduct.routeData = routesBuilder.forProduct(relatedProduct.id);
}

function copyProductFromState(result, state) {
  const page = result && result.page;
  const product = state.page.product;
  page.product.parentId = page.product.attributeSetId; //3.5. Product grouping � Quick order and search related changes

  if (
    state.routing.navigatingTo.location !== state.routing.location
    && page
    && product
    && product.id === page.product.id
  ) {
    page.product = {
      ...product,
      ...page.product,
      loaded: false,
    };
  }
}

function processMedia(result) {
  const page = result && result.page;
  if (page)
    page.product.media = page.product.media
      .map(media => {
        if (media.type === ProductMediaType.Video) {
          const videoData = parseVideoData(media.url);

          return {
            type: media.type,
            videoData,
          };
        }

        return media;
      })
      .filter(media => {
        if (media.type === ProductMediaType.Video)
          return !!media.videoData;

        return media.small || media.medium || media.large;
      });
}
